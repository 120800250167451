.text-wrap-wrap {
    text-wrap: wrap !important;
    white-space: normal !important;
}

.background-white {
    background-color: #ffffff !important;
}

.border-none {
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.apexcharts-xaxistooltip {
    display: none !important;
}

.apexcharts-heatmap-series rect:hover {
    fill: rgb(127, 127, 241) !important;
    color: white !important;;
}
/* .apexcharts-data-labels rect:hover {

}

.apexcharts-heatmap-series text {
    transition: fill 0.2s;
}

.apexcharts-heatmap-series rect:hover+text,
.apexcharts-heatmap-series text:hover {
    fill: white !important;
} */
.apexcharts-legend-marker{
    top: 0 !important;
}

/* .ant-spin-nested-loading {
    background-color: rgba(0, 0, 0, 0.5); 
} */
.isoLoginContent .ant-spin-nested-loading > div > .ant-spin{
    background-color: rgb(0 0 0 / 73%);
}
.ant-input-number-handler-wrap{
    display: none;
}
.tech-form .ant-form-item-label {
    display: inline-block !important;
    vertical-align: middle !important;
    text-align: center;
}
.leaflet-control-attribution{
    display: none !important;
}
.flx-space-bwn{
    display: flex !important;
    justify-content: space-between;
}
@keyframes odin-img-rotate1 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
.rotate-odin-image1 {
  animation: odin-img-rotate1 1s reverse infinite;
}

.custom-tabs .ant-tabs-tab {
    background-color: transparent; /* Default background */
    color: black; /* Default text color */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }
  
  .custom-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #0074a7 !important; /* Selected tab background */
    color: white !important; /* Selected tab text color */
  }
  
  .custom-tabs .ant-tabs-tab:hover {
    color: #0074a7; /* Hover text color */
  }

  .psm_po_card_pre{
    background-color: #f8f5f5;
    text-align: center;
    height: 150px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
  }