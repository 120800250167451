.floating-contact {
  position: fixed;
  right: -35px;
  bottom: calc(50vh - 57px);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  border-radius: 5px;
  z-index: 1;
}

.floating-beta {
  position: fixed;
  right: 38px;
  bottom: calc(15vh - 57px);
  /* transform: rotate(-90deg); */
  border-radius: 5px;
  z-index: 1;
  /* transition: transform .2s;  */
}

@media (max-width: 768px) {
  .floating-beta {
    display: none;
  }
}

.zoom {
  transition: transform .2s; /* Animation */
  cursor: pointer;
}

.zoom:hover {
  transform: scale(1);
}

.contactus {
  color: #ffffff !important;
  background-color: #2f75a8 !important;
}

.contact {
  color: #2f75a8 !important;
  background-color: #fbfbfb !important;
}
.hoveredImage {
  transform: scale(1.1); /* Increase size on hover */
  transition: transform 0.3s ease; /* Add smooth transition */
}