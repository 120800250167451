.bg-alice-blue {
    background-color: #e6f7ff;
}
.tech-form .ant-form-item-label label{
    width: 100% !important;
}
.tech-form {
    margin-bottom: 0 !important;
}
.modal-ticket-succ .ant-modal-content{
    border-radius: 5px 5px 10px 10px;
}