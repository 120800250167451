.page {
  box-sizing: border-box;
  page-break-after: always;
}

.generate-button {
  width: 100%;
}
.page-content{
  margin-bottom: 1rem !important;
}
.header {
  text-align: left;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 10px;
  height: 180px;
  line-height: 1;
}
.header img {
  max-width: 140px;
  margin: 10px;
  border-radius: 50%;
 
}
.omang-title {
  color: aliceblue;
  font-weight: 800;
  margin-bottom: 22px;
  margin-top: 3px;
  font-size: x-large;

}
.analytics-title {
  text-align: right;
  font-size: medium;
  font-weight: bold;
  color: #0a74da;
}

.footer {
  text-align: center;
  /* background-color: #0a74da; */
  color: white;
  height: 80px;
}
.modal-graph-container {
  width: 710px;
  height: 850px;
  /* width: 765px;
    height: 920px; */
  padding: 20px;
  margin-left: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.graph-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 50px;
  gap: 40px;
}

.graph-item {
  width: calc(100% - 10px);
  height: 250px; 
  box-sizing: border-box; 
}